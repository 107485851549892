// Here you can add other styles
.fore-color-green{
  color: green;
}
.fore-color-grey{
  color: grey;
}

// backgrounds
.back-color-green{
  background-color: green;
}
.back-color-grey{
  background-color: grey;
}
.back-color-light-grey{
  background-color: lightgrey;
}
